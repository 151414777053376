import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} showStatus={false} dynamicHeight={false}>
                <div>
                  <img src="img/intro-bg.jpg" />
                </div>
                <div>
                  <img src="img/dubai.jpg" />
                </div>
                <div>
                  <img src="img/hk.jpg" />
                </div>
                <div>
                  <img src="img/hongkong.jpg" />
                </div>
                <div>
                  <img src="img/palm.jpg" />
                </div>
                <div>
                  <img src="img/rideau.jpg" />
                </div>
                <div>
                  <img src="img/gov.jpg" />
                </div>
                <div>
                  <img src="img/cra.jpg" />
                </div>
                <div>
                  <img src="img/ita.jpg" />
                </div>
                <div>
                  <img src="img/cal.jpg" />
                </div>
                <div>
                  <img src="img/gst.jpg" />
                </div>
                <div>
                  <img src="img/cpa.jpg" />
                </div>
                {/* <div>
                  <img src="img/fast.jpg" />
                </div> */}

              </Carousel> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why2.map((d, i) => <li key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about
